import { Pipe, PipeTransform } from '@angular/core';
import { ListaFiltro } from '../model';

@Pipe({
  name: 'listaFilter',
  standalone: true,
})
export class ListaFilterPipe implements PipeTransform {
  transform(lista: any[], args: ListaFiltro[] | undefined): any {
    if (!lista || !args) {
      return lista;
    }

    return lista.filter((item) => {
      let retorno = true;
      for (let filtro of args) {
        if (filtro.flexible) {
          retorno =
            retorno &&
            item[filtro.atributo]
              .toLowerCase()
              .includes(filtro.valor.toLowerCase());
        } else {
          retorno = retorno && item[filtro.atributo] === filtro.valor;
        }
      }

      return retorno;
    });
  }
}
