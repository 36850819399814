import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { ConfirmacaoComponent } from './confirmacao/confirmacao.component';
import { DialogSpinnerComponent } from './dialog-spinner/dialog-spinner.component';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { ListaFilterPipe } from './pipes/lista-filter.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgIconsModule } from '@ng-icons/core';
import { heroXMark } from '@ng-icons/heroicons/outline';

@NgModule({ exports: [ListaFilterPipe], imports: [CommonModule,
        CoreRoutingModule,
        FormsModule,
        MatInputModule,
        MatFormFieldModule,
        NgIconsModule.withIcons({
            heroXMark,
        }),
        MatButtonModule,
        MatListModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        ConfirmacaoComponent,
        DialogSpinnerComponent,
        ImageDialogComponent,
        ListaFilterPipe], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class CoreModule {}
