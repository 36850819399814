import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-dialog-spinner',
    templateUrl: './dialog-spinner.component.html',
    styleUrls: ['./dialog-spinner.component.scss'],
    standalone: true,
    imports: [MatProgressSpinnerModule, NgIf],
})
export class DialogSpinnerComponent implements OnInit {
  texto: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.texto = data;
  }

  ngOnInit(): void {}
}
